export enum Action {
  Ui = "https://doacrop.pathway.com.np",
  // Base = "https://doacropapi.pathway.com.np",
  Base = "http://127.0.0.1:8000",
  API = "/api",
  User = "auth/user/userdetail",
  CustomUser = "auth/user/customuser",
  GeneralUsers = "auth/user/generaluserlist",
  AdminUsers = "auth/user/highleveluserlist",
  ViewerUser = "auth/user/vieweruserlist",
  DOADUser = "auth/user/doaduserlist",
  ConsumerCompany = "auth/user/consumercompanylist",
  SeedCompany = "auth/user/seedcompanylist",
  ImprovedSeedConsumer = "auth/user/improvedseedconsumerlist",
  PendingUsers = "auth/user/pending_users",
  ApprovedUsers = "auth/user/approved_users",
  RejectedUsers = "auth/user/rejected_users",
  RegisterCompany = "auth/user/registercompany",
  UpdateUserDocuments = "auth/user/registercompany/update_documents",
  ApproveUserDocuments = "auth/user/registercompany/<userId>/approve_document",
  RejectUserDocuments = "auth/user/registercompany/<userId>/reject_document",
  ReverifyDocuments = "auth/user/registercompany/reverify_all",
  UserDocuments = "auth/user/registercompany/documents",
  UserDetailPdf = "auth/user/export_user_detail",
  EditCompanyProfile = "auth/user/editprofile",
  ApproveCompany = "auth/user/approvecompany",
  RejectCompany = "auth/user/rejectcompany",
  Role = "role",
  UserInRole = "userinrole",

  Province = "auth/user/province",
  District = "auth/user/district",
  Palika = "auth/user/palika",
  BankList = "auth/user/banklist",

  Register = "auth/user/register",
  Activate = "auth/user/activate",
  ChangeUsername = "auth/user/auth_users/set_username",
  ChangePassword = "auth/user/auth_users/set_password",
  ForgotPassword = "auth/user/forgotpassword",
  ResetPassword = "auth/user/auth_users/reset_password_confirm",
  CheckAccess = "auth/user/accesscheck",
  ObtainToken = "auth/user/obtaintoken",
  RefreshToken = "auth/user/refreshtoken",
  VerifyToken = "auth/user/verifytoken",
  SocialAuth = "auth/user/socialauth",
  ChangeProfilePicture = "auth/user/changepp",
  UserExists = "auth/user/userexists",
  LicensenoExists = "auth/user/seed_license_exist",
  UserReport = "auth/user/company_reports",

  CropName = "crop",
  Crops = "crops",
  CropCategory = "cropcategory",
  CropVarietiesRangeFiltered = "cropvarietiesrangefiltered",
  CropType = "croptype",
  Images = "image",
  CropVarietiesMetaVar = "cropvarietiesmetavar",
  CropvVarietyForAdmin = "cropvarietyforadmin",
  CropVarieties = "cropvarieties",
  CompareCrops = "comparecropvariety",
  CropVarietiesFilterable = "searchablevar",
  CropVariable = "cropvarietiesvariables",
  CropVarietiesValues = "cropvarietiesvalues",
  Image = "image",
  CropImage = "cropimage",
  SimilarCropVariety = "similarcropvarieties",
  CropCategoryNavBar = "cropcategorynavbar",
  SortableVariables = "sortablevariables",
  Sort = "sort",
  CropVarietyComment = "comment",
  AllComments = "all_comments",
  CommentSeenStatus = "comment_seen_status",
  RecentCropVarieties = "recentvarieties",
  DSADGroup = "dsadgroup",
  DSADNotice = "dsad_notice",
  CropVarietyReply = "reply",
  SupplierCropVariety = "supplierbycropvariety",
  CropVarietiesDetail = "cropvarietiesdetail",
  ExportPdf = "exportpdf",

  Preferences = "preferences",
  Subsidy = "subsidy",
  SubsidyCropList = "subsidy/crop_list",
  FyWiseSubsidy = "subsidy/fy_wise_subsidy",
  FyWiseSubsidyCropList = "subsidy/fy_wise_crop_list",
  SubsidyBatch = "subsidy_batch",
  CurrentSubsidyBatch = "subsidy_batch/current_subsidy",
  SubsidySchedule = "subsidy_schedule",

  SeedType = "seedtype",
  SeedClass = "seedclass",
  Demand = "demand",
  DemandConfirm = "demandconfirm",
  DSADCrop = "dsadcrop",
  Supply = "supply",
  SupplyConfirm = "supplyconfirm",
  UploadSourceSeedSaleBill = "supply/<supplyId>/update_sold_quantity",
  Supplier = "supplier",
  Cluster = "cluster",
  Allocate = "allocate",
  ExcelReportDistribution = "excelreportdistribution",
  AdminAllocation = "adminallocation",
  UserAllocation = "userallocation",
  CurrentMarketStat = "currentmarketstat",
  SupplierStat = "supplierstat",
  SupplierInfo = "auth/user/supplierinfo",
  Notices = "notice",
  AdminSupply = "adminsupply",
  AdminTotalSupplyQuantityAPI = "admin_supply_total_quantity",
  AdminTotalDemandQuantityAPI = "admin_demand_total_quantity",
  ApproveSalesDetail = "adminsupply/<supplyId>/approve_sales",
  RejectSalesDetail = "adminsupply/<supplyId>/reject_sales",
  ApproveAllocation = "approve_allocation",
  AdminDemand = "admindemand",
  AdminCount = "admin_count",
  FiscalYear = "fiscalyear",
  Distribution = "distribution",
  BalanceSheetReport = "exportreport",
  SubsidyNotice = "subsidy_notice",
  SubsidyReport = "subsidy_report",
  DemandReport = "demand_report",
  SupplyReport = "supply_report",
  CategoryReport = "categoryreport",
  MunicipalityWiseAllocationReport = "municipality_wise_allocation_report",
  AdHocReport = "adhocreport",
  DemandForecastReport = "demandforecastreport",
  UnsoldAllocationReport = "unsoldallocationreport",
  UnsoldAllocationReportDetail = "unsoldallocationreportdetail",
  UnsoldAllocationDemandDetail = "unsoldallocationdemanddetail",
  TransactionReport = "transaction_report",
  CropCarousel = "cropcarousel",
  CropVariableSetting = "cropvariablesetting",
  SendMail = "sendmaillog",
  SendMailLogGroup = "sendmailloggroup",
  PreRenderCropVariableDetail = "prerender",
  Dashboard = "dashboard",
  SupplyDemandTrend = "trend",
  CropIdBySlug = "cropidbyslug",
  CropVarietyIdBySlug = "varietyidbyslug",
  DistributionSoldBill = "distributionsoldbill",
  AllocationRootReceipt = "allocationrootreceipt",
  MyAllocation = "myallocation",
  VerifyAllocation = "verifyallocation",

  SupplyVsDemandReport = "reports/supply_demand_report",

  Purchase = "purchase",
  AdminPurchase = "admin_purchase",
  PurchaseConfirm = "purchaseconfirm",
  Sale = "sale",
  AdminSale = "admin_sale",
  SaleConfirm = "saleconfirm",

  UploadDocument = "upload_document",
  CurrentSubsidyDocument = "upload_document/subsidy_batch_document"
}
